import React from 'react';
import './App.css';
import { Slide } from 'react-slideshow-image';


class SlideShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      galleria: [],
     };
    this.myRefSlide = React.createRef();
  }

  componentDidMount(){
    let galleriaTemp = [];
    for(let i=0;i<this.props.slideImages.length;i++)
      galleriaTemp.push(
        <div className="each-slide" key={`foto${i}`}>
          <div style={{'backgroundImage': `url(${this.props.slideImages[i]})`}}>

          </div>
        </div>
      )
      this.setState({galleria:galleriaTemp});

    //setTimeout(()=>{ this.myRefSlide.current.goNext(); console.log(this.myRefSlide.current)}, 3000);
    if(this.props.num===1){
      setInterval(()=>{
        this.myRefSlide.current.goNext();
        //console.log(this.myRefSlide.current)
      }, 4000);
    }else if(this.props.num===2){
      setInterval(()=>{
        this.myRefSlide.current.goNext();
        //console.log(this.myRefSlide.current)
      }, 4500);
    }else if(this.props.num===3){
      setInterval(()=>{
        this.myRefSlide.current.goNext();
        //console.log(this.myRefSlide.current)
      }, 5000);
    }else if(this.props.num===4){
      setInterval(()=>{
        this.myRefSlide.current.goNext();
        //console.log(this.myRefSlide.current)
      }, 5500);
    }

  }

  handleClick = () =>{
    let url;
    if(this.props.num===1){
      url = `https://goo.gl/maps/PeDV8oFPr6YVjvHz8`;
    }else if(this.props.num===2){
      url = `https://goo.gl/maps/tgJrPH3sCE9iy3tF7`;
    }else if(this.props.num===3){
      return;//url = `https://goo.gl/maps/PeDV8oFPr6YVjvHz8`;
    }else if(this.props.num===4){
      return;//url = `https://goo.gl/maps/tgJrPH3sCE9iy3tF7`;
    }
    window.open(url);
  }



  render() {
    return (
      <div onClick={this.handleClick} className="slide-container">
        <Slide ref={this.myRefSlide} {...this.props.properties}>
          {this.state.galleria}
        </Slide>
      </div>
    );
  }
}

export default SlideShow;
