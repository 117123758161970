import React from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Phone from '@material-ui/icons/Phone';
import Mail from '@material-ui/icons/Mail';
import Map from '@material-ui/icons/Map';
import Watch from '@material-ui/icons/Watch';
import FacebookIcon from '@material-ui/icons/Facebook';
import SlideShow from '../SlideShow';
import Orari from '../Orari';
import FormDiContatto from '../FormDiContatto';
import FloatingLogo from '../FloatingLogo';



const slideImages3Mappa = [
  'imagesSlide/3/3Ingresso2.jpeg',
  'imagesSlide/3/mappa.webp',
];

const slideImagesNarniMappa = [
    'imagesSlide/Narni/imgslide1.webp',
    'imagesSlide/Narni/mappa.webp',
];

const slideImages3Foto = [
  'imagesSlide/3/3Ingresso1.jpeg',
  'imagesSlide/3/sposa.webp',
  'imagesSlide/3/3Ingresso3.jpeg',
  //'imagesSlide/3/imgslide1.webp',
  'imagesSlide/3/3Ingresso4.jpeg',
  //'imagesSlide/3/imgslide2.webp',
  'imagesSlide/3/scarpetappeti.jpeg',
  //'imagesSlide/3/imgslide3.png',
  //'imagesSlide/3/mappa.webp',
  'imagesSlide/3/3Interno5.jpeg',
  //'imagesSlide/3/scarpe.webp',
];

const slideImagesNarniFoto = [
    //'imagesSlide/Narni/imgslide1.webp',
    'imagesSlide/Narni/imgslide2.webp',
    'imagesSlide/Narni/NarniIngressoDentro.jpeg',
    //'imagesSlide/Narni/mappa.webp',
    'imagesSlide/Narni/scarpe.webp',
    //'imagesSlide/Narni/sposa.webp',
    'imagesSlide/Narni/photo0.jpeg',
];

const properties3 = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: false,
  autoplay: false,
  onChange: (oldIndex, newIndex) => {
    //console.log(`1slide transition from ${oldIndex} to ${newIndex}`);
  }
}

const propertiesNarni = {
  duration: 2000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: false,
  autoplay: false,
  onChange: (oldIndex, newIndex) => {
    //console.log(`2slide transition from ${oldIndex} to ${newIndex}`);
  }
}

const properties3Foto = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: true,
  autoplay: false,
  onChange: (oldIndex, newIndex) => {
    //console.log(`1slide transition from ${oldIndex} to ${newIndex}`);
  }
}

const propertiesNarniFoto = {
  duration: 2000,
  transitionDuration: 500,
  infinite: true,
  indicators: false,
  arrows: true,
  autoplay: false,
  onChange: (oldIndex, newIndex) => {
    //console.log(`2slide transition from ${oldIndex} to ${newIndex}`);
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },

  primaryGrid: {
    padding: 10,
    width: ((window.innerWidth/window.innerHeight)>1)?(window.innerWidth*0.9):(window.innerWidth),//se desktop stringi il container
  },

  logo:{
    width:`100%`,
  },

  icona:{
    marginBlockEnd: `-0.2em`,

  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  mail:{
    color: theme.palette.text.secondary,
  },
  telefono:{
    color: theme.palette.text.secondary,
    textDecoration:"none",
  },

  paperWithImage:{
    padding: theme.spacing(0),
    paddingTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontWeight: "bold",
  },

  paperMain:{
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundColor:"#bed4ea5c",
    //backgroundImage:`url("/imagesSlide/logoPNG.webp")`,
    //backgroundSize: "cover",
  },

  paperLaNostraStoriaMobile:{
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundImage:`url("/imagesSlide/logoPNG.webp")`,
    backgroundSize: "cover",
  },

  paperLaNostraStoriaDesk:{
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundImage:`url("/imagesSlide/logoPNG.webp")`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },

  paperContatti:{
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundColor:"#bed4ea5c",
  },

  paperContattiChild:{
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundImage:`url("/imagesSlide/logoJPG.webp")`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },

  titoloPaperLaNostraStoria:{
    color:`#e25388`,
    fontStyle:"italic",
    fontFamily: "coursive",
    fontWeight: "800",
    fontSize: "xx-large",
    textShadow: `-1px 0 #7c4e7c, 0 1px #7c4e7c, 1px 0 #7c4e7c, 0 -1px #7c4e7c`,
    marginBlockStart: `0.1em`,
    marginBlockEnd: `0.2em`,
  },

  titoloPaperDoveCiTroviamo:{
    color:`#e25388`,
    fontStyle:"italic",
    fontFamily: "coursive",
    fontWeight: "800",
    fontSize: "xx-large",
    textShadow: `-1px 0 #7c4e7c, 0 1px #7c4e7c, 1px 0 #7c4e7c, 0 -1px #7c4e7c`,
    marginBlockStart: `0.1em`,
    marginBlockEnd: `0.2em`,
  },

  titoloPaperLeNostreFoto:{
    color:`#e25388`,
    fontStyle:"italic",
    fontFamily: "coursive",
    fontWeight: "800",
    fontSize: "xx-large",
    textShadow: `-1px 0 #7c4e7c, 0 1px #7c4e7c, 1px 0 #7c4e7c, 0 -1px #7c4e7c`,
    marginBlockStart: `0.1em`,
    marginBlockEnd: `0.2em`,
  },

  titoloPaperINostriContatti:{
    color:`#e25388`,
    fontStyle:"italic",
    fontFamily: "coursive",
    fontWeight: "800",
    fontSize: "xx-large",
    textShadow: `-1px 0 #7c4e7c, 0 1px #7c4e7c, 1px 0 #7c4e7c, 0 -1px #7c4e7c`,
    marginBlockStart: `0.1em`,
    marginBlockEnd: `0.2em`,
  },

  formDiContattoTitolo:{
    //color:`#e25388`,
    fontStyle:"italic",
    fontFamily: "coursive",
    fontWeight: "800",
    fontSize: "larger",
    //textShadow: `-1px 0 #7c4e7c, 0 1px #7c4e7c, 1px 0 #7c4e7c, 0 -1px #7c4e7c`,
    marginBlockStart: `0.1em`,
    marginBlockEnd: `0.2em`,
  },

  textField:{
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '95%',
  },

  containerForm: {
      display: 'flex',
      flexWrap: 'wrap',
  },


}));




function Home() {
  const classes = useStyles();


  return (
    <div className="App">
      <header className="App-header">
        <div className={classes.root}>
          <Grid className={classes.primaryGrid} container spacing={2}>

            {(window.innerWidth>=600)?(
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <a title="Lavanderia tre monumenti terni | logo" hrefLang="it" style={{textDecoration: 'none'}} href="index.html">
                <img className={classes.logo} alt="logo lavanderia tre 3 monumenti terni" src="logoJPG.webp">
                </img>
              </a>
            </Grid>
          ):(
            <FloatingLogo></FloatingLogo>
        )}



            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>


              <Paper className={ (window.innerWidth>window.innerHeight) ? classes.paperLaNostraStoriaDesk : classes.paperLaNostraStoriaMobile }>


                <a title="Lavanderia tre monumenti terni | la nostra storia" hrefLang="it" style={{textDecoration: 'none'}} href="index.html">
                  <h2 className={classes.titoloPaperLaNostraStoria}>La nostra storia</h2>
                </a>

                A Terni e provincia la vostra lavanderia a secco, fin dai primi anni '70 del secolo scorso, è la <b>Lavanderia 3 Monumenti</b>. E da allora con professionalità, competenza, passione e formazione continua eseguiamo lavaggi civili e per privati, negozi, uffici, per locali ed enti pubblici.
                Grazie all'esperienza accumulata ed il costante impegno nell'avere, sempre, i macchinari per lavanderia più efficaci ed avanzati, siamo in grado di lavare qualsiasi tipo di capo ed indumento, dagli abiti da sposa ai vestiti da sera e da cerimonia. Dai giacchini in pelle fino ai piumini d'oca, dai divani ai tappeti.
                Avete problemi con le vostre borse e le vostre scarpe? Sconfiggeremo qualsiasi macchia e le faremo tornare all'antico splendore e in oltre forniamo il servizio <b>lavanderia a domicilio</b> con ritiro e consegna in tutta la provincia di Terni. Contattateci per saperne di più e per un preventivo gratuito.

                </Paper>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Paper className={classes.paperMain}>
                <a title="Lavanderia tre monumenti terni | Dove ci troviamo" hrefLang="it" style={{textDecoration: 'none'}} href="dovesiamo.html">
                  <h2 className={classes.titoloPaperDoveCiTroviamo}>Dove ci troviamo</h2>
                </a>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paper className={classes.paperWithImage}>
                     Sede via Tre Monumenti
                     <SlideShow num={1} properties={properties3} slideImages={slideImages3Mappa}></SlideShow>
                  </Paper>
                </Grid>

                <br></br>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paper className={classes.paperWithImage}>
                     Sede via Narni
                     <SlideShow num={2} properties={propertiesNarni} slideImages={slideImagesNarniMappa}></SlideShow>
                  </Paper>
                </Grid>


                </Paper>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Paper className={classes.paperMain}>
                <a title="Lavanderia tre monumenti terni | le nostre foto" hrefLang="it" style={{textDecoration: 'none'}} href="lenostrefoto.html">
                  <h2 className={classes.titoloPaperLeNostreFoto}>Le nostre foto</h2>
                </a>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paper className={classes.paperWithImage}>
                     Via Tre Monumenti
                     <SlideShow num={3} properties={properties3Foto} slideImages={slideImages3Foto}></SlideShow>
                  </Paper>
                </Grid>

                <br></br>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paper className={classes.paperWithImage}>
                     Via Narni
                     <SlideShow num={4} properties={propertiesNarniFoto} slideImages={slideImagesNarniFoto}></SlideShow>
                  </Paper>
                </Grid>


                </Paper>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Paper className={classes.paperContatti}>
                <a title="Lavanderia tre monumenti terni | contatti" hrefLang="it" style={{textDecoration: 'none'}} href="contatti.html">
                  <h2 className={classes.titoloPaperINostriContatti}>Contatti</h2>
                </a>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paper className={classes.paperContattiChild}>
                    <h4 className={classes.formDiContattoTitolo}>Form di contatto</h4>
                    <FormDiContatto></FormDiContatto>
                  </Paper>
                </Grid>

                <br></br>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paper className={classes.paperContattiChild}>
                     <div onClick={()=>{window.open("https://goo.gl/maps/PeDV8oFPr6YVjvHz8");}}><Map className={classes.icona}></Map>Via Tre Monumenti, 7<br></br></div>
                     <Phone className={classes.icona}></Phone> <a className={classes.telefono} target="_blank" rel="noopener noreferrer" href={`tel:0744 426883`}>0744 426883</a> - <a className={classes.telefono} target="_blank" rel="noopener noreferrer" href={`tel:+393286935510`}>3286935510</a><br></br>
                     <Mail className={classes.icona}></Mail> <a className={classes.mail} target="_blank" rel="noopener noreferrer" href={`mailto:info@lavanderia3monumenti.com`}> info@lavanderia3monumenti.com</a><br></br>
                     <Watch className={classes.icona}></Watch><Orari num={1}></Orari><br></br>
                   </Paper>
                </Grid>

                <br></br>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paper className={classes.paperContattiChild}>
                     <div onClick={()=>{window.open("https://goo.gl/maps/tgJrPH3sCE9iy3tF7");}}><Map className={classes.icona}></Map>Via Narni, 39H<br></br></div>
                     <Phone className={classes.icona}></Phone> <a className={classes.telefono} target="_blank" rel="noopener noreferrer" href={`tel:0744 800006`}>0744 800006</a> - <a className={classes.telefono} target="_blank" rel="noopener noreferrer" href={`tel:+393286935510`}>3286935510</a><br></br>
                     <Mail className={classes.icona}></Mail> <a className={classes.mail} target="_blank" rel="noopener noreferrer" href={`mailto:info@lavanderia3monumenti.com`}> info@lavanderia3monumenti.com</a><br></br>
                     <Watch className={classes.icona}></Watch><Orari num={2}></Orari><br></br>
                   </Paper>
                </Grid>

                <br></br>

                LAVANDERIA TRE MONUMENTI SNC DI ROCCHETTI RAFFAELA & C.<br></br>
                Sede Legale: LAVANDERIA TRE MONUMENTI SNC - V. Tre Monumenti 7/9 - TERNI (TR) - P.Iva: 00571570555

                <a target="_blank" rel="noopener noreferrer" title="Lavanderia 3 Monumenti Facebook" hrefLang="it" style={{textDecoration: 'none'}} href="https://www.facebook.com/lavanderia3monumentiTerni">
                  <FacebookIcon className={classes.icona}> </FacebookIcon>
                </a>

                <span className={"iubenda-fix"}>
                  <a href="//www.iubenda.com/privacy-policy/65459586" className="iubenda-white iubenda-embed" title="Privacy Policy">Privacy Policy</a>
                </span>


                </Paper>
            </Grid>

          </Grid>
        </div>


      </header>
    </div>
  );
}

export default Home;
