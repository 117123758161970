import React from 'react';
import './App.css';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';



class FormDiContatto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nomeValue: '',
      nomeError: false,
      nomeDisabled: false,
      emailValue: '',
      emailError: false,
      emailDisabled: false,
      numeroValue: '',
      numeroError: false,
      numeroDisabled: false,
      testoValue: '',
      testoError: false,
      testoDisabled: false,
      buttonDisabled: false,
      insideButton: 'Invia',
      buttonStyle:{
        marginTop: '8px',
        //backgroundColor:'#ff0000'
      },
      // galleria: [],
     };

  }

  componentDidMount(){
    //console.log(window.location.protocol)
    // if(window.location.protocol==="http:")
    //   window.location.replace("https://lavanderia3monumenti.altervista.org")
  }

  handleClick = () =>{

    let errore=false;
    //controllo dei field
    //nome max 50 caratteri

    //email deve avere chiocciola e il punto max 50 caratteri
    //numero devono essere solo numeri (oppure +39347..)
    //testo max 2500 caratteri

    if((this.state.nomeValue.length===0)||(this.state.nomeValue.length>50)){
      this.setState({nomeError:true})
      errore=true;
    }
    if((this.state.emailValue.length===0)||(this.state.emailValue.length>50)||(!(this.state.emailValue.includes("@")))||(!(this.state.emailValue.includes(".")))){
      this.setState({emailError:true})
      errore=true;
    }
    if((this.state.numeroValue.length===0)||(isNaN(this.state.numeroValue))){
      this.setState({numeroError:true})
      errore=true;
    }
    if((this.state.testoValue.length===0)||(this.state.testoValue.length>2500)){
      this.setState({testoError:true})
      errore=true;
    }

    if(!errore&&this.noErrors()){
      let response;
      //fetch
      //fetch(`https://us-central1-lavanderia-b2413.cloudfunctions.net/test?nome=${this.state.nomeValue}&email=${this.state.emailValue}&numero=${this.state.numeroValue}&testo=${this.state.testoValue}`)
      fetch(`https://lavanderia3monumenti.altervista.org/email.php?nome=${this.state.nomeValue}&email=${this.state.emailValue}&numero=${this.state.numeroValue}&testo=${this.state.testoValue}`)
      .then(resp=>{
        response = resp;
        //console.log(resp);
        if(response.ok&&response.status===200){
          this.disableAll();
          this.setState({insideButton:"Richiesta inviata correttamente"});
          // fetch(`https://us-central1-noisiamobellissimi2.cloudfunctions.net/sendMessageToSamLavanderia?nome=${this.state.nomeValue}&email=${this.state.emailValue}&numero=${this.state.numeroValue}&testo=${this.state.testoValue}`)
          // .then(resp=>{}).catch(error=>{})
        }else{
          this.disableAll();
          this.setState({
            buttonStyle:{
              marginTop: '8px',
              backgroundColor:'#ff0000'
            }
          })
          this.setState({insideButton:"Impossibile inoltrare la richiesta"})
        }
      }).catch(error=>{
        this.disableAll();
        this.setState({
          buttonStyle:{
            marginTop: '8px',
            backgroundColor:'rgba(255, 0, 0, 0.69)'
          }
        })
        this.setState({insideButton:"Impossibile inoltrare la richiesta"})
      })
      }

  }

  noErrors = () =>{
    if(
      (
        !this.state.nomeError
      )&&(
        !this.state.emailError
      )&&(
        !this.state.numeroError
      )&&(
        !this.state.testoError
      )
    ){return true}else{return false}
  }

  handleChange = (event, targetName) =>{
    //console.log(event.target.value)
    if(targetName===("nome")){
      this.setState((prevState, props) => {
        if(prevState.nomeError)
          return ({
            nomeError: false
          })
        });
      this.setState({nomeValue:event.target.value})
    }else if(targetName===("email")){
      this.setState((prevState, props) => {
        if(prevState.emailError)
          return ({
            emailError: false
          })
        });
      this.setState({emailValue:event.target.value})
    }else if(targetName===("numero")){
      this.setState((prevState, props) => {
        if(prevState.numeroError)
          return ({
            numeroError: false
          })
        });
      this.setState({numeroValue:event.target.value})
    }else{
      this.setState((prevState, props) => {
        if(prevState.testoError)
          return ({
            testoError: false
          })
        });
      this.setState({testoValue:event.target.value})
    }

  }

  disableAll = () =>{
    //console.log(event.target.value)
      this.setState((prevState, props) => {
        if(!prevState.nomeDisabled)
          return ({
            nomeDisabled: true
          })
        });
      this.setState((prevState, props) => {
        if(!prevState.emailDisabled)
          return ({
            emailDisabled: true
          })
        });
      this.setState((prevState, props) => {
        if(!prevState.numeroDisabled)
          return ({
            numeroDisabled: true
          })
        });

      this.setState((prevState, props) => {
        if(!prevState.testoDisabled)
          return ({
            testoDisabled: true
          })
        });

      this.setState((prevState, props) => {
        if(!prevState.buttonDisabled)
          return ({
            buttonDisabled: true
          })
        });
  }



  render() {
    return (
       <FormControl className={"formControl"}>
         <TextField
          id="nome"
          className={"textField"}
          placeholder="Nome Cognome e/o Azienda"
          inputProps={{ 'aria-label': 'bare' }}
          error={this.state.nomeError}
          onChange={(event)=>{this.handleChange(event,"nome")}}
          disabled={this.state.nomeDisabled}
        />
        <TextField
          id="email"
          className={"textField"}
          placeholder="Email"
          inputProps={{ 'aria-label': 'bare' }}
          error={this.state.emailError}
          onChange={(event)=>{this.handleChange(event,"email")}}
          disabled={this.state.emailDisabled}
        />
        <TextField
          id="numero"
          className={"textField"}
          placeholder="Numero di Telefono"
          inputProps={{ 'aria-label': 'bare' }}
          error={this.state.numeroError}
          onChange={(event)=>{this.handleChange(event,"numero")}}
          disabled={this.state.numeroDisabled}
        />
        <TextField
          id="testo"
          className={"textField"}
          placeholder="scrivi qui il contenuto del messaggio"
          multiline
          inputProps={{ 'aria-label': 'bare' }}
          error={this.state.testoError}
          onChange={(event)=>{this.handleChange(event,"testo")}}
          disabled={this.state.testoDisabled}
        />
        <Button style={this.state.buttonStyle} fullWidth={true} onClick={this.handleClick} variant="contained" color="primary" className={"button"} disabled={this.state.buttonDisabled}>
          {this.state.insideButton}
          {/* This Button uses a Font Icon, see the installation instructions in the docs.
          <Icon className={classes.rightIcon}>send</Icon>*/}
        </Button>
        <p style={{fontSize:12}}>
          Cliccando su Invia dichiari di aver preso visione dell'Informativa ai sensi del Decreto Legislativo 196/2003 e del Regolamento (UE) 2016/679 del Parlamento Europeo e del Consiglio del 27 Aprile 2016 (GDPR).
          <span className={"iubenda-fix"}>
            <a href="//www.iubenda.com/privacy-policy/65459586" className="iubenda-white iubenda-embed" title="Privacy Policy"> Privacy Policy</a>
          </span>
          Autorizzi inoltre altresì LAVANDERIA TRE MONUMENTI SNC ed i suoi partner al trattamento dei miei dati personali per attività promozionali, pubblicitarie e di marketing dei propri prodotti e servizi.


        </p>
      </FormControl>
      );
  }
}

export default FormDiContatto;
