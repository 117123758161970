import React from 'react';
import './App.css';


class Orari extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orari: 'Aperti dal Lunedi al Venerdi 8:30/13:00 16:00/20:00 e il Sabato 8:30/13:00',
     };
  }

  componentDidMount(){
    if(this.props.num===1)
      fetch("https://lavanderia3monumenti.altervista.org/getOrari.php").then(resp=>resp.json()).then(resp=>this.setState({orari:resp.orari[0]}));
      else
      fetch("https://lavanderia3monumenti.altervista.org/getOrari.php").then(resp=>resp.json()).then(resp=>this.setState({orari:resp.orari[1]}));

  }

  render() {
    return (
      <span  className="slide-container">
        {this.state.orari}
      </span>
    );
  }
}

export default Orari;
