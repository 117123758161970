import React from 'react';
import './App.css';
//import TextField from '@material-ui/core/TextField';
//import FormControl from '@material-ui/core/FormControl';
//import Button from '@material-ui/core/Button';



class FloatingLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //moving: false,
      larghezza:0,
     };

  }

  componentDidMount(){
    //let img = document.getElementById("immagineLogo");
    //alert(window.innerWidth)
    this.setState({larghezza:window.innerWidth,larghezzaMax:window.innerWidth})
    window.addEventListener('scroll', this.handleScroll);

    let divHome = document.getElementById("home");
    divHome.style.paddingTop = `${window.innerWidth*147/380}px`
    // const transition = document.querySelector('.logoMax');
    // transition.addEventListener('transitionstart', this.handleStartTransition);
    // transition.addEventListener('transitionend', this.handleEndTransition);
  }

  // componentDidUpdate(propsPrecedenti, statePrecedente){
  // }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) =>{

    let larghezza = this.state.larghezzaMax-window.scrollY;
    if(this.state.larghezza===(0.33*this.state.larghezzaMax)&&larghezza<=(0.32*this.state.larghezzaMax))
      return;

    // this.setState({larghezza:larghezza})
    //console.log(this.state.larghezzaMax-window.scrollY);
    if(larghezza<=(0.32*this.state.larghezzaMax))
      larghezza=0.33*this.state.larghezzaMax;
    // this.setState({larghezza:larghezza})
    this.setState((prevState, props) => {
      //console.log(prevState.larghezza-larghezza);
      //if(Math.abs(prevState.larghezza-larghezza)>2)
        return ({
          larghezza:larghezza
        })
      });
  }

  onClickLogo = () =>{

    let scrollOptions = {
      left: 0,
      top: -window.scrollY,
      behavior: 'smooth'
    }
    //console.log("click")
    window.scrollBy(scrollOptions);
  }


  render() {
    return (
      <div className={`logoDivParent`}>
        <div onClick={this.onClickLogo} className={`logoDiv`}>
            <img id="immagineLogo" width={this.state.larghezza} className={`logo`} alt="logo lavanderia tre 3 monumenti terni" src="logoJPG.webp">
            </img>
        </div>
      </div>
       );
  }
}

export default FloatingLogo;
